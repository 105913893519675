import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const CustomerCaseStudyBoxes = ({ limit, heading }) => {
    const [studies, setStudies] = useState([]);
    const { casestudies } = useStaticQuery(graphql`
        query CaseStudyQuery {
            casestudies: allContentfulCaseStudy(filter: { hidden: { eq: false } }) {
                nodes {
                    id
                    page_heading
                    slug
                    customer {
                        company_name
                        one_color_logo {
                            file {
                                url
                            }
                        }
                    }
                }
                totalCount
            }
        }
    `);

    const allCaseStudies = casestudies.nodes;
    const num = limit !== null ? limit : casestudies.totalCount;

    useEffect(() => {
        const shuffle = (array) => {
            return [...array].sort(() => Math.random() - 0.5).slice(0, num);
        };
        const shuffled = shuffle(allCaseStudies);
        setStudies(shuffled);
    }, [allCaseStudies, num]);

    return (
        <section className="st-section">
            <div className="st-feature content">
                <h2 className="st-heading--2">{heading}</h2>
                <ul className="st-logo-boxes">
                    {studies.map((study) => (
                        <li className="logo-box" key={study.id}>
                            <Link to={`/customers/${study.slug}`} className="logo-box-border">
                                <div className="spacer">
                                    <img
                                        src={study.customer.one_color_logo.file.url}
                                        alt={study.customer.company_name}
                                    />
                                </div>
                                <div className="description">{study.page_heading}</div>
                                <p className="resource-link">Read more &rarr;</p>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default CustomerCaseStudyBoxes;
