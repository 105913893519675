import React from "react";
import MarkdownContainer from "../misc/markdown-container";

const CustomerTestimonial = ({ quote, logoUrl = null, logoAltText = null }) => {
    return (
        <section className="st-section">
            <div className="st-testimonial">
                <MarkdownContainer containerClasses={`testimonial-content`} content={quote} />
                {logoUrl && logoAltText && <img width="100" src={logoUrl} alt={logoAltText} />}
            </div>
        </section>
    );
};

export default CustomerTestimonial;
